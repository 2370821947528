














































































import { projectModule } from '@/store'
import { RawLocation } from 'vue-router'
import { commentFilters } from '@/data/comments'
import { suggestionFilters } from '@/data/suggestions'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { isFlexibleDocumentItemWithComments, isFlexibleDocumentItemWithSuggestions } from '@/helpers/FlexibleDocument'

import { FlexibleDocumentItem } from '@/models/flexibleDocument/FlexibleDocumentItem'

import { FlexibleDocumentUpdateItemAllocateUsersRequest } from '@/requests/flexibleDocument/FlexibleDocumentUpdateItemAllocateUsersRequest'

import ElementAudit from './Audit.vue'
import ElementComments from './Comments.vue'
import ElementSuggestions from './Suggestions.vue'

import Users from '@/components/list/Users.vue'
import Tabs from '@/components/widgets/Tabs.vue'
import UserPicker from '@/components/inputs/UserPicker.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import DropdownFilter from '@/components/inputs/DropdownFilter.vue'
import DeleteModal from '@/components/proposal/detail/DeleteModal.vue'

type AvailablePages = 'Suggestions' | 'Audit'

type clonedRawLocation = RawLocation

@Component({
  components: {
    Tabs, DeleteModal, SmallLoader, DropdownFilter, UserPicker, Users, ElementAudit, ElementComments, ElementSuggestions
  }
})
export default class ElementIndex extends Vue {

  @Prop() private element!: FlexibleDocumentItem

  @Prop() private backRoute!: clonedRawLocation

  @Prop({ default: () => ['Suggestions', 'Audit'] }) private availablePages!: AvailablePages[]

  @Prop({ default: true }) private readonly!: boolean

  private currentPage: AvailablePages = 'Suggestions'

  private loading: boolean = false
  private selectedCommentFilter: string | null = null
  private selectedSuggestionFilter: string | null = null
  private commentFilters: SelectItem[] = commentFilters
  private suggestionFilters: SelectItem[] = suggestionFilters

  private showDeleteModal: boolean = false

  private allocatedUsersForm: { form: FlexibleDocumentUpdateItemAllocateUsersRequest, loading: boolean } = {
    loading: false, form: new FlexibleDocumentUpdateItemAllocateUsersRequest(this.element)
  }

  private get componentPage(): string {
    return `Element${this.currentPage}`
  }

  private get projectUsers() {
    return projectModule.users
  }


  private refreshElement(): void {
    this.$emit('update')
  }


  private goToPage(page: AvailablePages): void {
    this.currentPage = page
  }

  private pageCounter(page: AvailablePages): string {
    switch (page) {
      case 'Suggestions':
        return isFlexibleDocumentItemWithSuggestions(this.element) ? `(${this.element.suggestion_count})` : ''
      default:
        return ''
    }
  }

  // DELETE
  private closeDeleteModal(): void {
    this.showDeleteModal = false
  }

  private async deleteHandler(): Promise<void> {
    this.loading = true
    try {
      await this.element.delete()
      this.closeDeleteModal()
      this.$emit('delete')
      // Go back
      this.$router.push(this.backRoute)
    } catch (err) {
      throw err
    } finally {
      this.loading = false
    }
  }

  // Everytime we open the dropdown we set the form since the data could be changes due to websocket updates
  private setAllocationForm() {
    this.allocatedUsersForm.form = new FlexibleDocumentUpdateItemAllocateUsersRequest(this.element)
  }

  private cancelAllocation(toggle: () => void) {
    this.allocatedUsersForm.form = new FlexibleDocumentUpdateItemAllocateUsersRequest(this.element)
    toggle()
  }

  private async allocateUsers(toggle: () => void) {
    this.allocatedUsersForm.loading = true
    try {
      await this.element.allocateUsers(this.allocatedUsersForm.form)
      // this.element.audits.get()
      toggle()
    } catch (e) {
      console.error(e)
    } finally {
      this.allocatedUsersForm.loading = false
    }
  }

}
