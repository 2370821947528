



































import { projectModule, userModule, stateModule } from '@/store'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { canPerformAction } from '@/helpers/canPerformAction'

import {
  isFlexibleDocumentChapterResource,
  isFlexibleDocumentItemWithComments,
  isFlexibleDocumentSectionResource,
  isFlexibleDocumentTitlePageResource,
  isFlexibleDocumentWidgetResource,
} from '@/helpers/FlexibleDocument'

import { AuthUserResource } from '@/models/users/AuthUserResource'
import { CommentResource } from '@/models/comments/CommentResource'
import { ProjectUserResource } from '@/models/projects/ProjectUserResource'
import { FlexibleDocumentItemResource } from '@/models/flexibleDocument/FlexibleDocumentItemResource'

import NoItems from '@/components/partials/NoItems.vue'
import AddComment from '@/components/comments/AddComment.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import AuditComment from '@/components/comments/AuditComment.vue'

// component
@Component({
  components: {
    NoItems,
    AddComment,
    SmallLoader,
    AuditComment,
  },
})
export default class ElementComments extends Vue {
  @Prop()
  private readonly commentFilter!: string | null

  @Prop()
  private readonly element!: FlexibleDocumentItemResource

  @Prop({ default: true })
  private readonly readonly!: boolean

  private loading: boolean = false

  @Watch('element.comments.data.length')
  private onCommentsLengthChange(): void {
    this.$nextTick(() => {
      this.scrollBottom()
    })
  }

  private get canComment(): boolean {
    return isFlexibleDocumentItemWithComments(this.element)
      ? canPerformAction(this.element.permissions, 'can_comment') &&
          !this.readonly
      : false
  }

  private get filteredComments(): CommentResource[] {
    if (isFlexibleDocumentItemWithComments(this.element)) {
      if (this.commentFilter) {
        return this.element.comments.items.filter(
          (c) => c.status === this.commentFilter,
        )
      }
      return this.element.comments.items
    }
    return []
  }

  private get users(): ProjectUserResource[] {
    return projectModule.users
  }

  private get user(): AuthUserResource | null {
    return userModule.user || null
  }

  private created(): void {
    this.scrollBottom()
  }

  private deleteComment(id: number): void {
    if (isFlexibleDocumentItemWithComments(this.element)) {
      this.element.comments.deleteComment(id)
    }
  }

  private async getComments(): Promise<void> {
    return;
    // if (isFlexibleDocumentItemWithComments(this.element)) {
    //   this.loading = true
    //   try {
    //     await this.element.comments.get()
    //     this.scrollBottom()
    //   } finally {
    //     this.loading = false
    //   }
    // }
  }

  private async addComment(message: string): Promise<void> {
    if (isFlexibleDocumentItemWithComments(this.element)) {
      this.loading = true
      try {
        await this.element.comments.post(message)
        this.scrollBottom()
      } finally {
        this.loading = false
      }
    }
  }

  private scrollBottom(): void {
    const wrapper = this.$refs.wrapper as HTMLElement
    if (!wrapper) return
    wrapper.scrollTop = wrapper.scrollHeight
  }
}
