















import {Route, Location} from 'vue-router'
import {Component, Vue, Prop} from 'vue-property-decorator'

import {
  isFlexibleDocumentSectionResource,
  isFlexibleDocumentWidgetResource,
  isFlexibleDocumentTitlePageResource,
  isFlexibleDocumentChapterResource
} from '@/helpers/FlexibleDocument'

import {ProjectResource} from '@/models/projects/ProjectResource'
import {FlexibleDocumentResource} from '@/models/flexibleDocument/FlexibleDocumentResource'
import {FlexibleDocumentItemResource} from '@/models/flexibleDocument/FlexibleDocumentItemResource'

import SmallLoader from '@/components/loaders/SmallLoader.vue'
import ElementIndex from '@/components/FlexibleDocumentElement/Index.vue'

type AvailablePages = 'Suggestions' | 'Audit'

@Component({
  components: {
    SmallLoader,
    ElementIndex
  }
})
export default class FlexibleDocumentElementDetail extends Vue {
  @Prop()
  private project!: ProjectResource

  @Prop()
  private document!: FlexibleDocumentResource

  private loading: boolean = false
  private element: FlexibleDocumentItemResource | null = null

  private get availablePages(): AvailablePages[] {
    const availablePages: AvailablePages[] = []
    if (this.element?.type === 'Widget') {
      availablePages.push('Suggestions')
    }

    availablePages.push('Audit')

    return availablePages
  }

  private get backRoute(): Location {
    const params = {...this.$route.params}
    const query = {...this.$route.query}
    delete params.element_id
    return {name: 'projects-flexible-document', params, query}
  }

  // Route hooks
  private async created(): Promise<void> {
    if (this.$route.params.element_id) {
      try {
        this.getData(this.$route.params.element_id)
      } catch {
        this.$router.push(this.backRoute)
      }

    }
  }

  private beforeRouteLeave(to: Route, from: Route, next: any) {
    // Will destroy the socket of the current element
    this.destroy()
    next()
  }

  private async beforeRouteUpdate(to: Route, from: Route, next: any): Promise<void> {
    if (to.params.element_id !== from.params.element_id) {
      // Will destroy the socket of the current element
      this.destroy()
      try {
        await this.getData(to.params.element_id)
      } catch {
        next(false)
      }
    }
    next()
  }

  private destroy() {
    if (this.element &&
        (
            isFlexibleDocumentSectionResource(this.element) ||
            isFlexibleDocumentWidgetResource(this.element) ||
            isFlexibleDocumentTitlePageResource(this.element) ||
            isFlexibleDocumentChapterResource(this.element)
        )
    ) {
      this.element.destroy()
    }
  }

  private async getData(id: string): Promise<void> {
    this.loading = true
    try {
      const {data} = await this.document.getElement(id)
      this.element = data
    } finally {
      this.loading = false
    }
  }

  private update(): void {
    this.$emit('update')
  }

  private emitDelete(): void {
    this.$emit('delete')
  }
}
